header {
    width: 100%;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
}

.blank-header.show {
    height: 70px;
}

header.header-gone {
    top: -100px;
    transition: all 0.3s ease-in-out;
}

header.sticky {
    position: fixed;
    top: 0;
    background-color: #ffffff !important;
    z-index: 99999;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.header-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}

header .left-sec {
    align-items: center;
    display: flex;
}

.header-sec img {
    max-width: 160px;
    width: 100%;
    height: auto;
}

.header-sec .menu-sec {
    margin-left: 30px;
}

.header-sec .menu-sec ul {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0;
    flex-wrap: wrap;
}

.header-sec .menu-sec ul li {
    margin: 0 10px;
}

.header-sec .menu-sec ul li a {
    color: var(--my-black3);
    font-weight: 500;
    text-align: center;
    font-size: 17px;
    text-decoration: none;
    position: relative;
    font-family: 'am';
}

.header-sec .menu-sec ul li a::before {
    position: absolute;
    content: "";
    width: 0;
    height: 2px;
    background-color: var(--my-orange);
    bottom: -5px;
    transition: all 0.3s ease-in-out;
}

.header-sec .menu-sec ul li a:hover,
.header-sec .menu-sec ul li.active a {
    color: var(--my-orange);
}

.header-sec .menu-sec ul li a:hover::before,
.header-sec .menu-sec ul li.active a::before {
    width: 100%;
}

.header-sec .mob-menu-icon-sec {
    cursor: pointer;
    position: relative;
    width: 30px;
    height: 30px;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.header-sec .mob-menu-icon-sec span {
    width: 30px;
    height: 3px !important;
    background-color: #000;
    display: block;
    margin-bottom: 5px;
    transition: all 0.5s ease-in-out;
}

.header-sec .mob-menu-icon-sec.active span {
    transform: rotate(45deg) translate(6px, 9px);
}

.header-sec .mob-menu-icon-sec.active span:nth-child(2) {
    background: transparent;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
}

.header-sec .mob-menu-icon-sec.active span:last-child {
    transform: rotate(-45deg) translate(1px, -6px);
}

header .right-sec {
    display: flex;
    align-items: center;
}

header .right-sec .book-demo-btn {
    color: var(--my-black3);
    margin-right: 20px;
    font-size: 17px;
    /* min-width: 110px; */
    font-family: 'am';
}

header .right-sec .book-demo-btn:hover {
    color: var(--my-orange);
    text-decoration: none;
}

header .right-sec .solid-btn-sec {
    margin-top: 0;
}

.collapse-menu-sec {
    max-height: 0;
    -webkit-transition: max-height 0.8s;
    -moz-transition: max-height 0.8s;
    -ms-transition: max-height 0.8s;
    -o-transition: max-height 0.8s;
    transition: max-height 0.8s;
    overflow: hidden;
    max-height: 0;
    text-align: center;
}

header.sticky .collapse-menu-sec {
    overflow-y: scroll;
}

.collapse-menu-sec.show {
    /* height: 500px; */
    max-height: 100vh;
}

.collapse-menu-sec ul {
    margin-top: 20px;
}

.collapse-menu-sec ul li {
    padding: 12px 0;
}

.collapse-menu-sec ul li a {
    color: var(--my-black3);
    font-size: 17px;
    font-family: 'am';
}

.collapse-menu-sec ul li a:hover,
.collapse-menu-sec ul li.active a {
    color: var(--my-orange);
    text-decoration: underline;
}

.mobile-link {
    display: none;
}

.menu-sec ul li,
.collapse-menu-sec ul li {
    position: relative;
}

.collapse-menu-sec ul li:nth-child(2),
.menu-sec ul li:nth-child(2) {
    padding-right: 15px;
}

.collapse-menu-sec ul li:nth-child(2)::after,
.menu-sec ul li:nth-child(2)::after {
    position: absolute;
    content: "New";
    font-size: 8px;
    background: var(--my-orange);
    color: #fff;
    border-radius: 10px;
    padding: 2px 5px;
    top: -6px;
}

.collapse-menu-sec ul li:nth-child(2)::after {
    top: 0px;
}

@media (max-width: 1199px) {
    .header-sec .menu-sec ul li a {
        font-size: 14px;
    }
    header .right-sec .book-demo-btn {
        font-size: 14px;
    }
}

@media (max-width: 1100px) {
    .header-sec .menu-sec {
        display: none;
    }
    .header-sec .mob-menu-icon-sec {
        display: flex;
        margin-top: 4px;
        margin-left: 20px;
    }
    .header-sec .menu-sec ul li a {
        font-size: 17px;
    }
    header .right-sec .book-demo-btn {
        font-size: 17px;
    }
}

@media (max-width: 991px) {
    .header-sec .menu-sec {
        display: none;
    }
    .header-sec .mob-menu-icon-sec {
        display: flex;
        margin-top: 4px;
        margin-left: 20px;
    }
}

@media (max-width: 767px) {
    .header-sec img {
        max-width: 160px;
    }
    header .right-sec .book-demo-btn {
        display: none;
    }
    .mobile-link {
        display: inherit;
    }
}

@media (max-width: 479px) {
    .header-sec {
        padding: 12px 0;
    }
    header .logo-img {
        max-width: 120px;
    }
    .header-sec .solid-btn-sec .my-btn {
        min-width: 130px !important;
        padding: 4px 15px;
        font-size: 16px;
    }
}