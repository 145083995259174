::-webkit-scrollbar {
    width: 12px;
    background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    background-color: #c9c9c9;
}


/* ::-webkit-scrollbar-thumb:hover {
    background: #a5a5a5;
} */


/* 
#d6d6d6 border
#6b6b6b text
#ededed box border

*/

:root {
    --my-blue: #005778;
    --my-light-blue: #1899a2;
    --my-orange: #f48120;
    --my-gray: #6b6b6b;
    --light-gray: #d6d6d6;
    --light-gray2: #ededed;
    --light-gray3: #ebebeb;
    --light-gray4: #f2f3f4;
    --light-gray5: #919396;
    --my-black: #000000;
    --my-black2: #231f20;
    --my-black3: #444443;
    --my-black4: #282827;
}

.gray-sec4,
.bg-gray4 {
    background: var(--light-gray4);
}

img {
    max-width: 100%;
    height: auto;
}

::selection {
    background-color: #02435d;
    color: #fff;
}

a {
    transition: all .3s ease-in-out;
    text-decoration: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/* pppppppppppp */

p {
    line-height: 1.4;
    font-size: 16px;
    color: var(--my-black2);
    font-family: "am";
}

p:last-child {
    margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--my-blue);
    font-size: 49px;
    font-family: "ab";
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
    text-align: left;
    line-height: 1.1;
}

h4 {
    color: var(--my-black4);
    font-size: 25px;
}

h5 {
    color: var(--my-black4);
    font-size: 18px;
    line-height: 1.2;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
    font-family: "ab";
}

h1 u,
h2 u {
    font-family: "ab";
    color: var(--my-blue);
    /* border-bottom: 3px solid var(--my-blue); */
    /* text-decoration: none; */
    position: relative;
    text-decoration-thickness: 2px;
}

h1:before,
h2:before {
    display: none;
}

h1 p {
    font-family: 'am';
    line-height: 1.2;
    margin-bottom: 0;
}

h1 strong,
h1 span,
h2 strong,
h2 span,
h3 strong,
h3 span,
h4 strong,
h4 span,
h5 strong,
h5 span,
h6 strong,
h6 span {
    color: var(--my-orange);
    font-family: 'ab';
}

.gray-sec {
    background: var(--light-gray3);
}

.black-sec {
    background: var(--my-black3);
}

.white-sec {
    background: #fff;
}

.my-sec {
    padding: 70px 0;
}

.dark-sec {
    position: relative;
    z-index: 9;
}

.dark-sec * {
    color: #fff;
}

.light-sec {
    position: relative;
    z-index: 9;
}

.light-sec * {
    color: var(--my-black4);
}

.dark-sec p {
    font-family: 'ar';
}

.dark-sec span {
    color: var(--my-orange);
}

.section-heading {
    font-size: 35px;
    color: var(--my-blue);
    font-family: 'am';
    margin: 0;
    line-height: 1;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
}

.section-heading span,
.section-heading strong {
    color: var(--my-orange);
    font-family: 'am';
    font-weight: normal;
}

.solid-btn-sec {
    display: inline-block;
    margin-top: 20px;
}

.solid-btn-sec .my-btn {
    background: var(--my-orange);
    display: inline-block;
    padding: 5px 10px;
    min-width: 160px;
    text-align: center;
    transition: all 0.3s ease-in-out;
    border-radius: 30px;
    font-family: 'ar';
    border: 1px solid transparent;
    font-size: 14px;
    text-decoration: none;
    font-family: 'am';
    color: var(--my-black2);
}

.solid-btn-sec .my-btn:focus {
    outline: none;
}

.solid-btn-sec .my-btn:hover {
    transition: all 0.3s ease-in-out;
    background: #ea7721;
}

.menu-section {
    transition: all 5s ease-in-out;
    left: 0;
    width: 100%;
}

.menu-section.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transition: all 5s ease-in-out;
}

.menu-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 36px;
    cursor: pointer;
    transition: all .5s ease-in-out;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.menu-btn-burger {
    width: 27px;
    height: 3px;
    background: #a9253c;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
    transition: all .5s ease-in-out;
}

.menu-btn-burger::before,
.menu-btn-burger::after {
    content: '';
    position: absolute;
    width: 27px;
    height: 3px;
    background: #a9253c;
    border-radius: 3px;
    transition: all .5s ease-in-out;
}

.menu-btn-burger::before {
    transform: translateY(-7px);
}

.menu-btn-burger::after {
    transform: translateY(7px);
}


/* Animation */

.menu-btn.open .menu-btn-burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
}

.menu-btn.open .menu-btn-burger::before {
    transform: rotate(45deg) translate(35px, -35px);
}

.menu-btn.open .menu-btn-burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
}

.thumbs .thumb-item {
    width: 60px;
    margin-right: 5px;
    float: left;
    margin-top: 10px;
    margin-bottom: 20px;
}

.thumbs img {
    cursor: pointer;
    border: 1px solid #dddd;
}

.thumbs img:hover {
    border: 1px solid #eaaa64;
}

.js-image-zoom__zoomed-image {
    z-index: 100;
}

.prod_right {
    overflow-x: hidden;
}

.product_detail {
    overflow-x: hidden;
}

.tab_title.Mui-selected {
    color: #a9253c !important;
    font-size: 16px;
}

.MuiTabs-indicator {
    background-color: #a9253c !important;
}

.quantity {
    margin: 10px 0;
    float: left;
}

.quantity-select {
    display: flex;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
}

.value-minus,
.value-plus {
    height: 30px;
    line-height: 24px;
    width: 30px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    font-size: 18px;
    color: #fff;
    text-align: center;
    vertical-align: bottom;
    transition: all 0.3s ease-in-out;
}

.quantity-select .entry.value-minus:before,
.quantity-select .entry.value-plus:before {
    content: "";
    width: 13px;
    height: 2px;
    background: #000;
    left: 50%;
    margin-left: -7px;
    top: 50%;
    margin-top: -0.5px;
    position: absolute;
}

.quantity-select .single-product.entry.value-plus:after {
    left: 52%;
    top: 48%;
}

.quantity-select .entry.value-plus:after {
    content: "";
    height: 13px;
    width: 2px;
    background: #000;
    left: 50%;
    margin-left: -2.4px;
    top: 50%;
    margin-top: -5.2px;
    position: absolute;
}

.value {
    cursor: default;
    width: 30px;
    height: 30px;
    padding: 8px 0px;
    color: #929191;
    line-height: 24px;
    border: 1px solid #E5E5E5;
    background-color: #E5E5E5;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quantity-select .entry.value-minus:hover,
.quantity-select .entry.value-plus:hover {
    background: #E5E5E5;
}

.quantity-select .entry.value-minus {
    margin-left: 0;
}

.web-header {
    border-bottom: 1px solid #e9e7e7;
}

.productSliderSec {
    position: relative;
    max-width: 1350px;
    margin: auto;
}

.productSliderSec .react-multiple-carousel__arrow--left {
    left: 3%;
    top: calc(50% - 20px);
}

.productSliderSec .react-multiple-carousel__arrow::before {
    color: #000;
    font-size: 22px;
}

.productSliderSec .react-multiple-carousel__arrow--left::before {
    content: "\e822";
}

.productSliderSec .react-multiple-carousel__arrow--right::before {
    content: "\e823";
}

.productSliderSec .react-multiple-carousel__arrow {
    background: none;
    min-width: 40px;
    min-height: 40px;
    border: 1px solid #a9253c;
    background-color: #fff;
}

.productSliderSec .react-multiple-carousel__arrow:hover {
    background: #ffce2a;
    border: 1px solid #ffce2a;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 30%);
}

.productSliderSec .react-multiple-carousel__arrow:hover.react-multiple-carousel__arrow::before {
    color: #fff;
}

.productSliderSec .react-multiple-carousel__arrow--right {
    right: 3%;
    top: calc(50% - 20px);
}

.productSliderSec .react-multi-carousel-list {
    position: inherit;
}